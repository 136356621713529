export const environment = {
  apiUrl: 'https://lokatix.net',
  production: true,
  cookieDomain: 'lokatix.net',
  googleAnalytics: 'G-5PTGTMBS81', //GTM-5PWHW6LC'',
  tracing: false,
  stripeKey:
    'pk_live_51N5uenLemcGBQjMP2Modluh35fKhp7jeMN4NXQfM9WYBvws0bjZo0cUNPPvaDoiP2vt5wb6uUEhXcjtNiUcR2Dvw00txUCuEZG',
  mapbox:
    'pk.eyJ1IjoiZnJlZGVyaWNjb3V0dSIsImEiOiJjbGRhM2phcHgwNzVlM3ZtdXN6OGR6cmZvIn0.Zvg_uzSAF5Zfd653JpnQ4g',
  places: 'c51d9727-ce83-403b-94ed-98beba3752dd',
  hideGoogleIndex: false,
  urlImage: 'https://lokatix.net',
  jwtName: 'jwt',
  tracesSampleRate: 0.2,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1,
  stripeId1: 'price_1OiO6aLemcGBQjMPR2g6mlgU',
  stripeId2: 'price_1OiO6VLemcGBQjMPUFkOFYnf',
  stripeId3: 'price_1OiO6iLemcGBQjMPyiv5DTOJ',
  stripeId4: 'price_1OiO6eLemcGBQjMPTmymynU4',
  stripeId5: 'price_1OiO6PLemcGBQjMPhAxeZceI',
  stripeId100: 'price_1OiO6LLemcGBQjMPw7mKNV1y',
  stripeId101: 'price_1OiO6HLemcGBQjMPcp0p43ly',
  stripeId102: 'price_1OiO6CLemcGBQjMPxXTyZaJu'
};
